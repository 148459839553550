import React from "react";
import Layout from "../components/layout/Layout";

const NotFound = () => (
  <Layout showHeader={false}>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFound;
